<script>
import Layout from "../../layouts/horizontal";
import PageHeader from "@/components/page-header";
import { mapGetters, mapActions, mapMutations } from 'vuex';
import moment from 'moment'
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { uuid } from 'vue-uuid';
import Swal from "sweetalert2";
import simplebar from "simplebar-vue";

export default {
  components: {
    Layout,
    PageHeader,
    simplebar
},
  data() {
    return {
      title: 'Execution',
      initializing: true,
      userSettings: null,
      userSettingsSaving: false,
      executionSettings: null,
      executionSettingsSaving: false,
      connecting: false,
      gettingBrokerPositions: false,
      executing: false,
      txUploading: false,
      txFileUploading: false,
      txFileUploaded: false,
      perPage: 20,
      currentPage: 1,
      uploadedOrders: [],
      txFileConfirming: false,
      taxLossHarvestBool: false,
      orderCancellation: false,
      warningFilter: false,
      errorFilter: false,

      settingsParametersFormId: null,
      settingsParametersFormOutsideClickConfig: {
        handler: this.hideSettingsParamtersForm,
        middleware: this.middleware,
        events: ["click"],
      },
      settingsParametersData: {
        price: null,
        ticksAway: 0,
        time: null
      },

      parametersFormId: null,
      parametersFormOutsideClickConfig: {
        handler: this.hideParamtersForm,
        middleware: this.middleware,
        events: ["click"],
      },
      parametersData: {
        price: null,
        ticksAway: 0,
        time: null
      },

      cloneOrderFormId: null,
      cloneOrderFormOutsideClickConfig: {
        handler: this.hideCloneForm,
        middleware: this.middleware,
        events: ["click"],
      },
    
      cloneSettingsOrdeFormId: null,
      cloneSettingsOrderFormOutsideClickConfig: {
        handler: this.hideSettingsCloneForm,
        middleware: this.middleware,
        events: ["click"]
      }
    };
  },
  created() {
    this.init();  
  },
  props: {
    id: {
      type: String
    },
    taxLossHarvest: {
      type: Array
    }
  },
  computed: {
    ...mapGetters('signalr', {
      signalrConnection: 'signalrConnection'
    }),
    ...mapGetters('execution', {
      item: 'item',
      loading: 'loading',
      interactiveBrokers: 'interactiveBrokers',
      orders: 'runtimeOrders',
      uploadTransactionsFromTime: 'uploadTransactionsFromTime'
    }),
    isBusy (){
      return this.initializing;
    },
    connected () {
      return this.interactiveBrokers.connected;
    },
    allOrdersSelected: {
      get() {
        const res = this.orders.find(x => !x.selected) ? false : true;
        return res;
      },
      set(v) {
        if(v) {
          this.selectAllRuntimeOrders();
        } else {
          this.unselectAllRuntimeOrders();
        }
      }
    },
    allUploadedOrdersSelected: {
      get() {
        return !this.uploadedOrders.some(order => !order.selected);
      },
      set(v) {
        if(v) {
          this.uploadedOrders = this.uploadedOrders.map(order =>{
            if(!order.error){
              return {...order, selected: true };
            }
            else{
              return {...order};
            }
          });
        } else {
          this.uploadedOrders = this.uploadedOrders.map(order =>{
            return {...order, selected: false };
          });
        }
      }
    },
    uploadedOrdersHaveSelected() {
      return this.uploadedOrders.some(order => order.selected);
    },
    uploadedOrdersHaveWarning() {
      return this.uploadedOrders.some(order => order.warning);
    },
    uploadedOrdersHaveError() {
      return this.uploadedOrders.some(order => order.error);
    },
    totalUploadedOrders() {
      return this.uploadedOrders.length;
    },
    totalWarningUploadedOrders() {
      return this.uploadedOrders.filter(order => order.warning && !order.error).length;
    },
    totalErrorUploadedOrders() {
      return this.uploadedOrders.filter(order => order.error).length;
    },
    filteredUploadedOrders() {
      let filteredOrders = this.uploadedOrders;
      if(this.warningFilter && this.errorFilter) {
        filteredOrders = this.uploadedOrders.filter(order => {
          return order.warning === true || order.error === true
        });
      } else if(this.errorFilter) {
        filteredOrders = this.uploadedOrders.filter(order => {
          return order.error; 
        });
      } else if(this.warningFilter) {
        filteredOrders = this.uploadedOrders.filter(order => {
          return order.warning && order.error == false;
        });
      }
      return filteredOrders;
    },
    paginatedUploadedOrders() {
      return this.filteredUploadedOrders.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    confirmUploadDisabled() {
      return this.txFileConfirming || !this.uploadedOrdersHaveSelected;
    }
  },
  watch: {
    signalrConnection: {
      handler: function (newValue) {
        if(newValue) {
          this.init();
        }
      }
    },
    item: {
      handler(val) {
        this.userSettings = JSON.parse(JSON.stringify(val.userSettings));
        if(!this.executionSettings) {
          this.executionSettings = JSON.parse(JSON.stringify(val.executionSettings));
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations('execution', {
      selectAllRuntimeOrders: 'SELECT_ALL_RUNTIME_ORDERS',
      unselectAllRuntimeOrders: 'UNSELECT_ALL_RUNTIME_ORDERS',
      setOrderSelected: 'SET_ORDER_SELECTED',
      setOrderType: 'SET_ORDER_TYPE',
      setOrderSize:'SET_ORDER_SIZE',
      setOrderParameters: 'SET_ORDER_PARAMETERS',
      setOrderPrice: 'SET_ORDER_PRICE',
      setItemId: 'SET_ITEM_ID',
      setUploadTransactionsFromTime: 'SET_UPLOAD_TRANSACTIONS_FROM_TIME'
    }),
    ...mapActions('execution', {
      initRuntime: 'initRuntime',
      cancellAllInteractiveBrokersOrders: 'cancellAllInteractiveBrokersOrders',
      executeOrderInteractiveBrokers: 'executeOrderInteractiveBrokers',
      updateInteractiveBrokersOrders: 'updateInteractiveBrokersOrders',
      uploadTransactionsFromInteractiveBrokers: 'uploadTransactionsFromInteractiveBrokers',
      uploadTransactionsFromFile: 'uploadTransactionsFromFile',
      validateTransactionsFromFile: 'validateTransactionsFromFile',
      loadItem: 'loadItem',
      saveUserSettings: 'saveUserSettings',
      saveExecutionSettings: 'saveExecutionSettings',
      loadUserSettings: 'loadUserSettings',
      loadTransactions: 'loadTransactions',
      loadExecutionSettings: 'loadExecutionSettings',
      loadPositions:'loadPositions',
      changeCurrency: 'changeCurrency',
      changeReBalanceCloseoutTrades : 'changeReBalanceCloseoutTrades',
      loadMoreItemTransactions: 'loadMoreItemTransactions',
      connectToInteractiveBrokers: 'connectToInteractiveBrokers',
      disconnectFromInteractiveBrokers: 'disconnectFromInteractiveBrokers',
      getBrokerPositionsFromInteractiveBrokers : 'getBrokerPositionsFromInteractiveBrokers',
      cancelOrderInteractiveBrokers: 'cancelOrderInteractiveBrokers',
      paperTrade: 'paperTrade'
    }),
    async validateTransactionAsFile() {
      let file = null;
      this.txFileUploading = true;
      try {
        const fileHandles = await window.showOpenFilePicker({
          types: [
            {
              description: 'Excel',
              accept: {
                'text/csv': ['.csv']
              }
            },
          ],
        });
        if(fileHandles.length > 0) {
            const fileHandle = fileHandles[0];
            file = await fileHandle.getFile();
        } else {
          this.txFileUploading = false;
          return;
        }
      } catch {
        this.txFileUploading = false;
        return;
      }
      console.log("Parsing start:", file);
      Papa.parse(file, {
        error: (error) => {
          console.log("Parsing error:", error);
          this.txFileUploading = false;
        },
        header: false,
        complete: async (results) => {
          console.log("Parsing start", results);
          this.uploadedOrders = [];
          let uploadedTickers = [];

          results.data.forEach(resultRow => {
            const order = this.orders.find(x => x.ticker === resultRow[0]);
            this.uploadedOrders.push({
              // OLD
              timeStamp: moment().toDate(),
              ticker: resultRow[0],
              orderQuantity: Number(resultRow[1]),
              price: Number(resultRow[2]),
              execId: uuid.v1(),
              orderId: uuid.v1(),
              productName: order ? order.productName : resultRow[0],
              warning: order ? false : true,
              error: false, 
              selected: order ? true : false,
              orderStatus: order ? '' : 'Not in Portfolio',
              isDuplicate: false

              // NEW
              // ticker: resultRow[0],
              // timeStamp: resultRow[1],
              // orderQuantity: resultRow[2],
              // orderSide: resultRow[3],
              // price: resultRow[4],
              // execId: uuid.v1(),
              // orderId: uuid.v1(), 
              // productName: order.productName,
            });
            uploadedTickers.push(resultRow[0]);
          });
          try {
            // API call return productName for orders NOT already in portfolio from backend?
            // shows ticker in productName column in table otherwise
            const response = await this.validateTransactionsFromFile(this.uploadedOrders);
            console.log('validateTransactionsFromFile', response);

            // Find duplicates for warning flag
            const dupTickers = uploadedTickers.filter((order, index) => 
                              uploadedTickers.indexOf(order) !== index);

            // Find ExecIds with Error Code for error flag
            const errorExecIds = response.Payload.reduce(function(filtered, txStatus) {
              if (txStatus.ErrorCode !== 0) { 
                filtered.push(txStatus.ExecId);
              }
              return filtered;
            }, []);

            this.uploadedOrders.filter(order => {
              if (errorExecIds.includes(order.execId)) {
                order.error = true;
                order.selected = false;
                order.orderStatus = 'Not Found';
                return;
              } else if (dupTickers.includes(order.ticker)) {
                order.warning = true;
                order.isDuplicate = true;
              }
            });

            if(this.uploadedOrders.length > 0) {
              this.txFileUploading = false;
              this.txFileUploaded = true;
            }
            else{
              throw "Uploaded orders array empty";
            }
          }
          catch(e) {
            console.error(e);
            this.txFileUploading = false;
            Swal.fire(
              'Upload Txs as File',
              'Txs could not be uploaded!',
              'error'
            );
          }
        }
      })
    },
    productRowColor (order) {
      let className = ''
      if(order.error){
        className = 'table-danger';
      } else if(order.warning){
        className = 'table-warning';
      }
      return className;
    },
    async cancelUpload() {
      this.txFileUploaded = false;
      this.uploadedOrders = [];
    },
    async confirmUploadTransaction() {
      this.txFileConfirming = true;
      let rows = [];
      console.log("Filtering orders", this.uploadedOrders);
      this.uploadedOrders.forEach(order => {
        if(order.selected && !order.error){
          rows.push({
            timeStamp: order.timeStamp,
            execId: uuid.v1(),
            orderId: order.execId,
            productName: order.productName,
            ticker: order.ticker,
            qty: order.orderSide === 'S' ? (Math.abs(order.orderQuantity) * -1) : Math.abs(order.orderQuantity),//order.orderQuantity,
            price: order.price
          });
        }
      });
      console.log("Uploading start", rows);
      try {
        await this.uploadTransactionsFromFile(rows);

        this.txFileConfirming = false;
        // if success, go to home page with success modal
        Swal.fire(
          'Upload Txs as File',
          'Txs were uploaded successfully!',
          'success'
        );
        this.$router.push("/");
      } catch {
        this.txFileConfirming = false;
        // if fail, stay on this page with fail modal
        Swal.fire(
          'Upload Txs as File',
          'Txs could not be uploaded!',
          'error'
        );
      }
    },
    async onUploadTransactions() {
      this.txUploading = true;
      try {
        await this.uploadTransactionsFromInteractiveBrokers();
      } finally {
          this.txUploading = false;
      }
    },
    async init() {
      if(!this.signalrConnection) return;
      this.setItemId(this.id);
      this.initializing = true;
      try {
        await this.initRuntime( { 
          indexId: this.id, 
          refresh: false, 
          taxLossHarvest: this.taxLossHarvest?.length > 0, 
          taxLossHarvestData: this.taxLossHarvest 
        });
        //this.title = '' + this.item.name;
      } catch(e) {
        console.log('init error', e);
      }
      this.initializing = false;
    },
    async generateOrders() {
      this.initializing = true;
      try {
        await this.initRuntime( { indexId: this.id, refresh: true });
        //this.title = '' + this.item.name;
      } catch(e) {
        console.log('init error', e);
      }
      this.initializing = false;      
    },
    async onSaveExecutionSettings () {
      var dto = JSON.parse(JSON.stringify(this.executionSettings));
       
      dto.forEach(x => {
         
          x.defaultOrderType = x.orderTypes.find(o => o.orderType === x.defaultOrderType);
        console.log('x2', x);
      });
      
      this.executionSettingsSaving = true;
      await this.saveExecutionSettings({value: dto});
      this.executionSettingsSaving = false;
    },
    async onSaveUserSettings () {
      const value = {
        ...this.userSettings,
        options: null,
        AnnualFixFee: this.userSettings.annualFixFee
      }
      this.userSettingsSaving = true;
      await this.saveUserSettings({value});
      this.userSettingsSaving = false;
    },
    async onConnect() {
      if(this.connecting) return;

      this.connecting = true;
      try{
        await this.connectToInteractiveBrokers();
      }catch(e) {
        console.error(e);
      }finally{
        this.connecting = false;
      }
    },
    async onDisconnect() {
      if(this.connecting) return;

      this.connecting = true;
      try{
        await this.disconnectFromInteractiveBrokers();
      }catch(e) {
        console.error(e);
      }finally{
        this.connecting = false;
      }
    },
    async onGetBrokerPositions() {
      if(this.gettingBrokerPositions) return;

      this.gettingBrokerPositions = true;
      try{
        await this.getBrokerPositionsFromInteractiveBrokers();
      }catch(e) {
        console.error(e);
      }finally{
        this.gettingBrokerPositions = false;
      }
    },
    async onExecute() {
      if(this.executing) return;
      const selectedOrders = this.orders.filter(x => x.selected);
      this.executing = true;
      try{
        for(let i = 0; i < selectedOrders.length; i++) {
          const o = selectedOrders[i];
          let ticker = o.ticker;
          if ( ticker.includes(":") )
          {
            var nameList = ticker.split(":");
            ticker = nameList[0];
          }
          const instrumentType = o.instrumentType;
          let securityType = "FUT";

          if ((instrumentType == "Stock") || (instrumentType == "STK")) {
              securityType = "STK";
          }

          if ((instrumentType == "Future") || (instrumentType == "Futures") || (instrumentType == "FUT")) {
              securityType = "FUT";
          }

          if ((instrumentType == "Option") || (instrumentType == "OPT")) {

              securityType = "OPT";
          }

          let price = 0;
          let orderType = "MKT";
         
          if (o.orderType.toLowerCase() == 'limit') {
            orderType = "LMT";
            price =  o.price;
          } else if (o.orderType.toLowerCase() == 'twap') {
            orderType = "TWAP";
          }

          let maturityDate = null;
          if(securityType === 'FUT') {
            const expirationMomemt = moment(o.expirationDate);
            const expiratioDate = expirationMomemt.toDate();
            var expires = new Date(expiratioDate.getFullYear(), expiratioDate.getMonth(), 1);
            maturityDate = expires
          }

          const m = {
            clOrderID: o.id,
            instrument: ticker,
            securityType,
            side: o.orderQuantity >= 0 ? 'Buy' : 'Sell',// o.orderSide.trim(),
            orderType,
            qty: Math.abs(o.orderQuantity),
            currency: o.currencyName,
            price,
            exchange: o.exchangeName,
            timeInForce: 'GTC',
            maturityDate,
            execInst: o.orderParameters,
            productName: o.productName,
            ticker
          };
          await this.executeOrderInteractiveBrokers(m);
        }
      }catch(e) {
        console.error(e);
      }finally{
        this.executing = false;
      }
      
      console.log('onExecute', {selectedOrders});
    },
    getOrderTypes (brokerName) {
      if(!this.userSettings) return [];
      //
      const broker = this.userSettings.options.brokers.find(x => x.brokerName === brokerName);
      if(!broker) return [];
      return broker.orderTypes;
    },
    getOrderSide (orderSide) {
      if(orderSide === "S"){
        return "Sell";
      } else if(orderSide === "B"){
        return "Buy";
      }
    },
    downloadOrders() {
      let text = '';
      this.orders.forEach(order => {
        let buyOrSell = "B";

        if (order.orderSide.toLowerCase() == "sell") {
          buyOrSell = "S";
        }
        text += order.ticker + ',';
        text += order.orderQuantity + ',';
        text += buyOrSell + '\n';
      });
      var blob = new Blob([text],
                { type: "text/plain;charset=utf-8" });
       saveAs(blob, "orders.csv");
    },
    async cancellAllOrders() {
      this.orderCancellation = true;
      try {
        await this.cancellAllInteractiveBrokersOrders();
      } finally {
        this.orderCancellation = false;
      }
    },
    toggleParametersForm(index) {
      if(this.parametersFormId === index) {
        this.parametersFormId = null;
      } else {
        this.parametersData.ticksAway = null;
        this.parametersData.price = null;
        this.parametersData.time = null;
        this.parametersFormId = index;
       this.$nextTick(() =>{
          if(this.$refs.paramInput) {
            if(Array.isArray(this.$refs.paramInput)) {
              if(this.$refs.paramInput.length > 0) {
                this.$refs.paramInput[0].focus();
              }
            } else {
              this.$refs.paramInput.focus();
            }
          }
       });
      }
    },
    saveParametersForm (index) {
      this.parametersFormId = null;
      const order = this.orders[index];
      if(order.orderType === 'Limit') {
        let parameters = "price=";
        let v = null;
        if(this.parametersData.price  !== null && this.parametersData.price !== undefined && this.parametersData.price !== "") {
          parameters += this.parametersData.price;
          v = this.parametersData.price;
        }
        this.setOrderParameters({index: index, parameters: parameters});
        this.setOrderPrice({index: index, price: v})
      } else if(order.orderType === 'Best Side Limit') {
        let parameters = "TicksAway=";
        if(this.parametersData.ticksAway  !== null && this.parametersData.ticksAway !== undefined && this.parametersData.ticksAway !== "") {
          parameters += this.parametersData.ticksAway;
        }
        this.setOrderParameters({index: index, parameters: parameters});
      } else if(order.orderType === 'TWAP') {
        let parameters = "time=";
        if(this.parametersData.time  !== null && this.parametersData.time !== undefined && this.parametersData.time !== "") {
          parameters += this.parametersData.time;
        }
        this.setOrderParameters({index: index, parameters: parameters});
      }
    },
    hideParamtersForm() {
      this.parametersFormId = null;
    },
    toggleCloneForm(index) {
      if(this.cloneOrderFormId === index) {
        this.cloneOrderFormId = null;
      } else {
        this.cloneOrderFormId = index;
      }
    },
    hideCloneForm() {
      this.cloneOrderFormId = null;
    },
    cloneOrder(sourceIndex, { checkedOnly, withTheSameType}) {

      const sourceOrder = this.orders[sourceIndex];
      const origArray =[...this.orders];
      let targetOrders = [...this.orders];
      if(checkedOnly) {
        targetOrders = targetOrders.filter(x => x.selected);
      }

      const orderParameters = sourceOrder.orderParameters;

      if(withTheSameType) {
        targetOrders = targetOrders.filter(x => x.orderType === sourceOrder.orderType);
      }

      if(!withTheSameType) {
        targetOrders.forEach(x => {
          this.setOrderType({index: origArray.indexOf(x), orderType: sourceOrder.orderType})
        });
      }

      targetOrders.forEach(x => {
        this.setOrderParameters({index: origArray.indexOf(x), parameters: orderParameters})
      });

      this.hideCloneForm();
    },
    toggleSettingsParametersForm(index) {
      const id = index;
      if(this.settingsParametersFormId === id) {
        this.settingsParametersFormId = null;
      } else {
        this.settingsParametersData.ticksAway = null;
        this.settingsParametersData.price = null;
        this.settingsParametersData.time = null;
        this.settingsParametersFormId = id;
       this.$nextTick(() =>{
          if(this.$refs.paramInput) {
            if(Array.isArray(this.$refs.paramInput)) {
              if(this.$refs.paramInput.length > 0) {
                this.$refs.paramInput[0].focus();
              }
            } else {
              this.$refs.paramInput.focus();
            }
          }
       });
      }
    },
    saveSettingsParametersForm (sourceIndex, ot) {

      const sourceRow = this.executionSettings[sourceIndex];
      const sourceOrderTypeSettings = sourceRow.orderTypes.find(x => x.orderType === ot);

      this.settingsParametersFormId = null;
    
      let v = '';
      if(ot === 'Limit') {
        v = "price=";
        
        if(this.settingsParametersData.price  !== null && this.settingsParametersData.price !== undefined && this.settingsParametersData.price !== "") {
          v += this.settingsParametersData.price;
        }
      
      } else if(ot === 'Best Side Limit') {
        v = "TicksAway=";
        if(this.settingsParametersData.ticksAway  !== null && this.settingsParametersData.ticksAway !== undefined && this.settingsParametersData.ticksAway !== "") {
          v += this.settingsParametersData.ticksAway;
        }
        
      } else if(ot === 'TWAP') {
        v = "time=";
        if(this.settingsParametersData.time  !== null && this.settingsParametersData.time !== undefined && this.settingsParametersData.time !== "") {
          v += this.settingsParametersData.time;
        }
      }
      sourceOrderTypeSettings.orderParameters = v;
    },
    hideSettingsParamtersForm() {
    
      this.settingsParametersFormId = null;
    },
    toggleSettingsCloneForm(index) {
      const id = index;
      if(this.cloneSettingsOrdeFormId === id) {
        this.cloneSettingsOrdeFormId = null;
      } else {
        this.cloneSettingsOrdeFormId = id;
      }
    },
    hideSettingsCloneForm() {
      this.cloneSettingsOrdeFormId = null;
    },
    cloneSettingsOrder(sourceIndex, { checkedOnly, withTheSameType}) {

      const sourceRow = this.executionSettings[sourceIndex];
      const orderType = sourceRow.defaultOrderType;
      const orderTypeSettings = sourceRow.orderTypes.find(x => x.orderType === orderType);

      let targetSettings = [...this.executionSettings];
      if(checkedOnly) {
        targetSettings = targetSettings.filter(x => x.selected);
      }

      if(withTheSameType) {
        targetSettings = targetSettings.filter(x => x.defaultOrderType === orderType);
      }
      
      targetSettings.forEach(row => {
        if(row === sourceRow) return;
        row.defaultOrderType = orderType;
        const otSettings = row.orderTypes.find(x => x.orderType === orderType);
        if(otSettings) {
          otSettings.orderParameters = orderTypeSettings.orderParameters;
        }
      });

      this.hideSettingsCloneForm();
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="[]" :is-busy="isBusy" />
    
    <div class="d-flex d-flex justify-content-end">
      <a href="https://c8studio2022.blob.core.windows.net/setups/c8gw_Win.1.0.0.zip" class="btn btn-c8 mb-1">
        <span class="btn-content">
          <i class="fa fa-download mr-1"></i>
          Download Gateway
        </span>
       
      </a>
    </div>

    <div class="card cardc8" v-if="!initializing">
      <div class="card-content m-3">
        <div v-if="!txFileUploaded">
          <b-tabs  content-class="p-3">
            <b-tab  >
              <template v-slot:title>
                <span class="">Execution</span>
              </template>
              <div >
                <div class="mb-4 mt-2">
                  <div class="row">
                    <div class="col-6">
                      <button class="btn btn-primary mr-2" v-if="!connected" :disabled="connecting" @click="onConnect">
                        <i class="fa fa-spin fa-spinner" v-if="connecting"></i>
                        Connect
                      </button>

                      <button class="btn btn-warning mr-4" v-if="connected" :disabled="orderCancellation" @click="cancellAllOrders">
                        <i class="fa fa-spin fa-spinner" v-if="orderCancellation"></i>
                        Cancel All
                      </button>

                      <div style="display: inline-block;"  v-if="connected">
                        <div class="input-group" >
                          <input type="time" class="form-control" style="width:120px" 
                            :value="uploadTransactionsFromTime"
                            @change="event => setUploadTransactionsFromTime(event.target.value)"
                          />
                          <div class="input-group-append">
                            <button class="btn btn-secondary mr-2" :disabled="txUploading"  @click="$nextTick(() => onUploadTransactions())">
                              <i class="fa fa-spin fa-spinner" v-if="connected && txUploading"></i>
                              Upload Txs
                            </button>
                          </div>
                        </div>
                      </div>

                      <button class="btn btn-secondary mr-2" v-if="connected" :disabled="connecting"  @click="onDisconnect">
                        <i class="fa fa-spin fa-spinner" v-if="connecting"></i>
                        Diconnect
                      </button>

                      <!-- <button class="btn btn-secondary me-2 mr-2" @click="generateOrders">Generate Orders</button> -->
                      
                    </div>
                    <div class="col-6 text-right">
                      
                      <button class="btn btn-primary mr-4" v-if="connected" :disabled="executing" @click="onExecute">
                        <i class="fa fa-spin fa-spinner" v-if="executing"></i>
                        Execute
                      </button>

                      <button class="btn btn-light mr-2" @click="paperTrade">Paper Trade</button>
                      <button class="btn btn-light mr-2" @click="downloadOrders">Download As File</button>
                      <button 
                        :disabled="txFileUploading" 
                        class="btn btn-secondary text-nowrap mr-2" 
                        @click="validateTransactionAsFile()"
                      >
                        <i class="fa fa-spin fa-spinner" v-if="txFileUploading"></i>
                        Upload Txs As File
                      </button>
                      <!-- <button class="btn btn-light">Send E-mail</button> -->
                    </div>
                  </div>
                
                </div>
                <div class="table-responsive">
                  <table class="table table-centered nowrap">
                    <thead>
                      <tr>
                        <th style="width:40px; min-width:40px; max-width:40px;" class="text-center">
                          <input type="checkbox" class=" mt-1" v-model="allOrdersSelected"/>
                        </th>
                        <th>
                          Product
                        </th>
                        <th>
                          Ticker
                        </th>
                        <th>
                          Order Type
                        </th>
                        <th>
                          Parameters
                        </th>
                        <th>
                          Direction
                        </th>
                        <th>
                          Size
                        </th>
                        <th>
                          Price
                        </th>
                        <th>
                          Status
                        </th>
                        <!-- <th>
                          Bid
                        </th>
                        <th>
                          Offer
                        </th> -->
                        <th>
                          Filled
                        </th>
                        <th>
                          Remaining
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="orders.length === 0">
                        <td colspan="100">
                          No orders found...
                        </td>
                      </tr>
                      <tr v-for="(order, orderIndex) in orders" :key="orderIndex">
                        <td style="width:40px; min-width:40px; max-width:40px;">
                          <input type="checkbox" class=" mt-1" :checked="order.selected" @change="e => setOrderSelected({index: orderIndex, selected: !order.selected})"/>
                        </td>
                        <td>
                          {{order.displayName || order.productName}}
                        </td>
                        <td>
                          {{order.ticker}}
                        </td>
                        <td>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <div class="btn-group">
                                <button type="button" class="btn btn-secondary btn-sm px-3" @click="toggleCloneForm(orderIndex)">
                                  <i class="fa fa-clone "></i>
                                </button>
                                <div 
                                  class="dropdown-menu dropdown-menu-end border show" 
                                  v-click-outside="cloneOrderFormOutsideClickConfig"
                                  v-if="cloneOrderFormId === orderIndex"
                                >
                                  <a href="javascript:void(0)" class="dropdown-item" style="cursor: pointer;" 
                                    @click="cloneOrder(orderIndex, {checkedOnly: true, withTheSameType: false})"
                                  >
                                    Clone to selected orders
                                  </a>
                                  <a href="javascript:void(0)" class="dropdown-item" style="cursor: pointer;"
                                    @click="cloneOrder(orderIndex, {checkedOnly: false, withTheSameType: false})"
                                  >
                                    Clone to all orders
                                  </a>
                                
                                  <div class="dropdown-divider"  v-if="order.orderType !== 'Market'"></div>
                                  <h6 class="dropdown-header"  v-if="order.orderType !== 'Market'">Only for the same order type</h6>
                                  <a href="javascript:void(0)" class="dropdown-item" style="cursor: pointer;"
                                    v-if="order.orderType !== 'Market'"
                                    @click="cloneOrder(orderIndex, {checkedOnly: true, withTheSameType: true})"
                                  >
                                    Clone to selected orders
                                  </a>
                                  <a href="javascript:void(0)" class="dropdown-item" style="cursor: pointer;"
                                    v-if="order.orderType !== 'Market'"
                                    @click="cloneOrder(orderIndex, {checkedOnly: false, withTheSameType: true})"
                                  >
                                    Clone to all orders
                                  </a>
                                </div>
                              </div>
                            </div>
                            <select class="form-control input-sm" :value="order.orderType" @change="event => setOrderType({index: orderIndex, orderType: event.target.value})">
                              <option v-for="(os, osIndex) in order.executionSettings.orderTypes" :key="osIndex" :value="os.orderType">
                              {{os.orderType}}
                              </option>
                            </select>
                          </div>
                        </td>
                        <td>
                          <b  v-if="order.orderType === 'Market'">N/A</b>
                          <div class="input-group" v-else>
                            
                            <input type="text" :value="order.orderParameters" 
                              class="form-control input-sm"
                              readonly
                            />
                            <div class="input-group-append" v-if="order.orderType !== 'Market'">
                              <div class="btn-group">
                                <button type="button" class="btn btn-secondary btn-sm px-3" @click="toggleParametersForm(orderIndex)">
                                  <i class="fa fa-pen "></i>
                                </button>
                                <div 
                                  class="dropdown-menu dropdown-menu-end border show" 
                                  v-click-outside="parametersFormOutsideClickConfig"
                                  v-if=" parametersFormId === orderIndex"
                                >
                                  <div class="px-3 pt-2">
                                    <div class="form-group" v-if="order.orderType === 'Limit'">
                                      <input 
                                        ref="paramInput" 
                                        class="form-control input-sm" 
                                        autofocus 
                                        placeholder="Price" 
                                        type="number" 
                                        v-model.number="parametersData.price"
                                        @keyup.enter="saveParametersForm(orderIndex)"
                                      />
                                    </div>
                                    <div class="form-group" v-if="order.orderType === 'Best Side Limit'">
                                      <input 
                                        ref="paramInput" 
                                        class="form-control input-sm" 
                                        autofocus 
                                        placeholder="Ticks Away" 
                                        type="number" 
                                        v-model.number="parametersData.ticksAway"
                                        @keyup.enter="saveParametersForm(orderIndex)"
                                      />
                                    </div>
                                    <div class="form-group" v-if="order.orderType === 'TWAP'">
                                      <input 
                                        ref="paramInput" 
                                        class="form-control input-sm" 
                                        autofocus 
                                        placeholder="Time" 
                                        type="time" 
                                        v-model="parametersData.time"
                                        @keyup.enter="saveParametersForm(orderIndex)"
                                      />
                                    </div>
                                  </div>
                                  <hr/>
                                  <div class="px-3 pb-2">
                                    <button class="btn btn-primary btn-block" type="button" 
                                    @click="saveParametersForm(orderIndex)">Save</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- <input type="text" :value="order.orderParameters" 
                            :disabled="order.orderType !== 'TWAP'"
                            :readonly="order.orderType !== 'TWAP'"
                            class="form-control input-sm" 
                            @keyup="event => setOrderParameters({index: orderIndex, parameters: event.target.value})"/> -->
                        </td>
                        <td>
                          <span v-if="order.orderQuantity > 0">Buy</span>
                          <span v-if="order.orderQuantity < 0">Sell</span>
                        </td>
                        <td>
                          <input type="number" :value="order.orderQuantity" class="form-control input-sm" @input="event => setOrderSize({index: orderIndex, size: event.target.value})"/>
                        </td>
                        <td>
                          <input type="text" :value="order.price" 
                          style="width:90px"
                          :disabled="order.orderType !== 'Limit'"
                          :readonly="order.orderType !== 'Limit'"
                          class="form-control input-sm" 
                          @keyup="event => setOrderPrice({index: orderIndex, price: event.target.value})"/>
                        </td>
                        <td>
                          <span class="badge badge-success" v-if="order.orderStatus.toLowerCase() === 'filled'">
                            {{order.orderStatus}}
                          </span>
                        <span class="badge badge-danger" v-if="order.orderStatus.toLowerCase() === 'rejected'">
                            {{order.orderStatus}}
                          </span>
                          <span class="badge badge-warning" v-if="order.orderStatus.toLowerCase() === 'cancelled'">
                            {{order.orderStatus}}
                          </span>
                          <span v-if="order.orderStatus.toLowerCase() !== 'cancelled' && order.orderStatus.toLowerCase() !== 'rejected' && order.orderStatus.toLowerCase() !== 'filled'">
                            {{order.orderStatus}}
                            <a href="javascript:void(0)" v-if="!order.processing && order.orderStatus.toLowerCase() !== 'unknown'" class="text-danger" @click="cancelOrderInteractiveBrokers({clOrderID: order.id})">
                              <span >[cancel]</span>
                            </a>
                            <span v-if="order.processing"> <i class="fa fa-spin fa-spinner"></i></span>
                          </span>
                        </td>
                        
                        <!-- <td>
                          {{(order.marketData || {}).bid || ''}}
                        </td>
                        <td>
                          {{(order.marketData || {}).offer || ''}}
                        </td> -->
                        <td class="text-right">
                          {{order.filled || 0}}
                        </td>
                        <td class="text-right">
                          {{order.remaining  || 0}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </b-tab>
            <b-tab @click="loadExecutionSettings">
              <template v-slot:title>
                <span class="">Execution Settings</span>
              </template>

              <div v-if="executionSettings">
                <div class="table-responsive">
                <table class="table table-centered nowrap">
                  <thead>
                    <tr>
                      <th>
                        Product
                      </th>
                      <th>
                        Ticker
                      </th>
                      <th>
                        Order Type
                      </th>
                      <th>
                        Order Parameters
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="executionSettings.length === 0">
                      <td colspan="100">
                        <span class="text-muted">No records found...</span>
                      </td>
                    </tr>
                    <tr v-for="(w, $index) in executionSettings" :key=" $index">
                      <td>
                        {{w.displayName || w.productName}}
                      </td>
                      <td>
                        {{w.ticker}}
                      </td>
                      <td>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <button type="button" class="btn btn-secondary btn-sm px-3" @click="toggleSettingsCloneForm($index)">
                              <i class="fa fa-clone "></i>
                            </button>
                            
                            <div 
                              class="dropdown-menu dropdown-menu-end border show" 
                              v-click-outside="cloneSettingsOrderFormOutsideClickConfig"
                              v-if="cloneSettingsOrdeFormId === $index"
                            >
                              <a href="javascript:void(0)" class="dropdown-item" style="cursor: pointer;" 
                                @click="cloneSettingsOrder($index, {checkedOnly: true, withTheSameType: false})"
                              >
                                Clone to selected products
                              </a>
                              <a href="javascript:void(0)" class="dropdown-item" style="cursor: pointer;"
                                @click="cloneSettingsOrder($index, {checkedOnly: false, withTheSameType: false})"
                              >
                                Clone to all products
                              </a>
                            
                              <div class="dropdown-divider" v-if="w.defaultOrderType  !== 'Market'"></div>
                              <h6 class="dropdown-header" v-if="w.defaultOrderType  !== 'Market'">Only for the same order type</h6>
                              <a href="javascript:void(0)" class="dropdown-item" style="cursor: pointer;"
                                v-if="w.defaultOrderType  !== 'Market'"
                                @click="cloneSettingsOrder($index, {checkedOnly: true, withTheSameType: true})"
                              >
                                Clone to selected products
                              </a>
                              <a href="javascript:void(0)" class="dropdown-item" style="cursor: pointer;"
                                v-if="w.defaultOrderType  !== 'Market'"
                                @click="cloneSettingsOrder($index, {checkedOnly: false, withTheSameType: true})"
                              >
                                Clone to all products
                              </a>
                            </div>
                          </div>
                          <select class="form-control input-sm" v-model="w.defaultOrderType">
                            <option v-for="(ot,ii) in w.orderTypes" :key="$index + '-' + ii" :value="ot.orderType">{{ot.orderType}}</option>
                          </select>
                        </div>
                      </td>
                      <td>
                        <b v-if="w.defaultOrderType  === 'Market'">N/A</b>
                        <div v-else>
                          <div class="input-group">
                            
                            <input type="text" class="form-control input-sm"
                              :value="w.orderTypes.find(x => x.orderType === w.defaultOrderType).orderParameters"  
                              readonly
                            />
                            <div class="input-group-append">
                              <div class="btn-group">
                                <button type="button" class="btn btn-secondary btn-sm px-3" @click="toggleSettingsParametersForm($index)">
                                  <i class="fa fa-pen "></i>
                                </button>
                                <div 
                                  class="dropdown-menu dropdown-menu-end border show" 
                                  v-click-outside="settingsParametersFormOutsideClickConfig"
                                  v-if="settingsParametersFormId === $index"
                                >
                                  <div class="px-3 pt-2">
                                    <div class="form-group" v-if="w.defaultOrderType  === 'Limit'">
                                      <input 
                                        ref="paramInput" 
                                        class="form-control input-sm" 
                                        autofocus 
                                        placeholder="Price" 
                                        type="number" 
                                        v-model.number="settingsParametersData.price"
                                        @keyup.enter="saveSettingsParametersForm($index, 'Limit')"
                                      />
                                    </div>
                                    <div class="form-group" v-if="w.defaultOrderType  === 'Best Side Limit'">
                                      <input 
                                        ref="paramInput" 
                                        class="form-control input-sm" 
                                        autofocus 
                                        placeholder="Ticks Away" 
                                        type="number" 
                                        v-model.number="settingsParametersData.ticksAway"
                                        @keyup.enter="saveSettingsParametersForm($index, 'Best Side Limit')"
                                      />
                                    </div>
                                    <div class="form-group" v-if="w.defaultOrderType  === 'TWAP'">
                                      <input 
                                        ref="paramInput" 
                                        class="form-control input-sm" 
                                        autofocus 
                                        placeholder="Time" 
                                        type="time" 
                                        v-model="settingsParametersData.time"
                                        @keyup.enter="saveSettingsParametersForm($index, 'TWAP')"
                                      />
                                    </div>
                                  </div>
                                  <hr/>
                                  <div class="px-3 pb-2">
                                    <button class="btn btn-primary btn-block" type="button" 
                                    @click="saveSettingsParametersForm($index, w.defaultOrderType)">Save</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      
                    </tr>
                  </tbody>
                </table>
              </div>

                <button type="button" class=" float-right btn btn-primary mt-4" :disabled="executionSettingsSaving" @click="onSaveExecutionSettings">
                  <i class="fa fa-save  mr-1" v-if="!executionSettingsSaving"></i>
                  <i class="fa fa-spin fa-spinner mr-1" v-if="executionSettingsSaving"></i>
                  Save
                </button>
              </div>
            </b-tab>
            <b-tab @click="loadUserSettings">
              <template v-slot:title>
                <span class="">User Settings</span>
              </template>

              <div v-if="userSettings">
                <div class="row">
                  <label class="mb-1 mt-3 font-weight-medium col-md-3">Gearing</label>
                  <input class="form-control col-md-9" v-model="userSettings.gearing"/>
                </div>
              
                <div class="row">
                  <label class="mb-1 mt-3 font-weight-medium col-md-3">AUS</label>
                  <input class="form-control  col-md-9" v-model.number="userSettings.aus"/>
                </div>
                <div class="row">
                  <label class="mb-1 mt-3 font-weight-medium col-md-3">AUS Currency</label>
                  <input class="form-control  col-md-9" v-model="userSettings.ausCurrency"/>
                </div>
                <div class="row">
                  <label class="mb-1 mt-3 font-weight-medium col-md-3">Start Date</label>
                  <input class="form-control  col-md-9" v-model="userSettings.startDate"/>
                </div>
                <div class="row">
                  <label class="mb-1 mt-3 font-weight-medium col-md-3">Annual Fix Fee</label>
                  <input class="form-control  col-md-9" v-model.number="userSettings.annualFixFee" readonly disabled/>
                </div>
                <div class="row">
                  <label class="mb-1 mt-3 font-weight-medium col-md-3">Annual Performance Fee</label>
                  <input class="form-control  col-md-9" v-model.number="userSettings.annualPerformanceFee" readonly disabled/>
                </div>
                
                <div class="row">
                  <label class="mb-1 mt-3 font-weight-medium col-md-3">Broker</label>
                  <select class="form-control  col-md-9" v-model="userSettings.broker" @change="($event) => onBrokerChanged($event.target.value) ">
                    <option v-for="(b,i) in userSettings.options.brokers" :key="i" :value="b.brokerName">
                      {{b.brokerName}}
                    </option>
                  </select>
                </div>
                
                <div class="row">
                  <label class="mb-1 mt-3 font-weight-medium col-md-3">Default Order Type</label>
                  <select class="form-control  col-md-9" v-model="userSettings.defaultOrderType" @change="($event) => onOrderTypeChanged(userSettings.broker, $event.target.value) ">
                    <option v-for="(ot,i) in getOrderTypes(userSettings.broker)" :key="i" :value="ot.orderType">
                      {{ot.orderType}}
                    </option>
                  </select>
                </div>

                <div class="row">
                  <label class="mb-1 mt-3 font-weight-medium col-md-3">Default Order Settings</label>
                  <input class="form-control  col-md-9" v-model="userSettings.defaultOrderSettings"/>
                </div>

                <div class="row">
                  <label class="mb-1 mt-3 font-weight-medium col-md-3">User Name</label>
                  <input class="form-control  col-md-9" v-model="userSettings.userName"/>
                </div>
                <div class="row">
                  <label class="mb-1 mt-3 font-weight-medium col-md-3">Password</label>
                  <input class="form-control  col-md-9" v-model="userSettings.password"/>
                </div>
                <div class="row">
                  <label class="mb-1 mt-3 font-weight-medium col-md-3">Email</label>
                  <input class="form-control  col-md-9" v-model="userSettings.email"/>
                </div>

                <button type="button" class=" float-right btn btn-primary mt-4" :disabled="userSettingsSaving" @click="onSaveUserSettings">
                  <i class="fa fa-save  mr-1" v-if="!userSettingsSaving"></i>
                  <i class="fa fa-spin fa-spinner mr-1" v-if="userSettingsSaving"></i>
                  Save
                </button>
              </div>
            </b-tab>
            
          </b-tabs>
        </div>
        <div v-if="txFileUploaded">
          <div class="mb-4 mt-2">
            <div class="row">
              <div class="col-4">
                <button class="btn btn-light mr-2" @click="cancelUpload">
                  <i class="fa fa-spin fa-spinner" v-if="connecting"></i>
                  Cancel
                </button>
              </div>
              <div class="col-4 text-center my-auto">
                <!-- <b-form-checkbox v-model="taxLossHarvestBool" switch>
                  Tax Loss Harvest
                </b-form-checkbox> -->
              </div>
              <div class="col-4 text-right">
                <b-button 
                  variant="outline-warning"
                  class="text-nowrap mr-2" 
                  :disabled="!uploadedOrdersHaveWarning"
                  :pressed="warningFilter"
                  @click="warningFilter = !warningFilter"
                >
                  <i class="fa fa-exclamation-triangle"></i>
                </b-button>
                <b-button 
                  variant="outline-danger" 
                  class="text-nowrap mr-2"
                  :disabled="!uploadedOrdersHaveError"
                  :pressed="errorFilter"
                  @click="errorFilter = !errorFilter"
                >
                  <i class="fa fa-times-circle"></i>
                </b-button>
                <button 
                  :disabled="txFileUploading" 
                  class="btn btn-secondary text-nowrap mr-2" 
                  @click="validateTransactionAsFile()"
                >
                  <i class="fa fa-spin fa-spinner" v-if="txFileUploading"></i>
                  Reupload
                </button>
                <button 
                  :disabled="confirmUploadDisabled" 
                  class="btn btn-primary text-nowrap mr-2" 
                  @click="confirmUploadTransaction()"
                >
                  <i class="fa fa-spin fa-spinner" v-if="txFileConfirming"></i>
                  Confirm
                </button>
              </div>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-md-8">
              <div v-if="uploadedOrdersHaveWarning && !uploadedOrdersHaveError" 
                class="alert alert-warning mx-5 mt-2"
              >
                <b>Warning!</b> One or more uploaded Products are duplicates or do not exist
                in the current Portfolio.
                <br/>
                Select the highlighted rows below to ignore the warning and include them in the
                upload when you press Confirm.
              </div>
              <div v-if="uploadedOrdersHaveError" class="alert alert-danger mx-5 mt-2">
                <b>Error!</b> One or more uploaded Products do not exist.
                They will not be uploaded if you press Confirm.
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="table-responsive">
              <div is="simplebar" style="max-height: 700px">
                <table class="table table-centered nowrap">
                  <thead style="position: sticky; top: 0;">
                    <tr>
                      <th style="width:40px; min-width:40px; max-width:40px;" class="text-center">
                        <input type="checkbox" class=" mt-1" v-model="allUploadedOrdersSelected"/>
                      </th>
                      <th>
                        Product
                      </th>
                      <th>
                        Ticker
                      </th>
                      <th>
                        Timestamp
                      </th>
                      <th>
                        Direction
                      </th>
                      <th>
                        Size
                      </th>
                      <th>
                        Price
                      </th>
                      <th>
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="txFileUploaded">
                    <tr v-for="(order, orderIndex) in paginatedUploadedOrders" :class="productRowColor(order)" :key="orderIndex">
                      <td style="width:40px; min-width:40px; max-width:40px;">
                        <input type="checkbox" class=" mt-1" :disabled="order.error" :checked="order.selected" v-model="order.selected"/>
                      </td>
                      <td>
                        {{order.displayName || order.productName}}
                      </td>
                      <td>
                        {{order.ticker}}
                      </td>
                      <td>
                        <input type="text" :value="moment(order.timeStamp).format('DD/MM/YYYY HH:mm:ss')"
                        :disabled="true"
                        style="width:150px"
                        class="form-control input-sm"/>
                      </td>
                      <td>
                        {{getOrderSide(order.orderSide)}}
                      </td>
                      <td>
                        <input 
                          class="form-control input-sm" 
                          type="number" 
                          :value="order.orderQuantity"
                          style="width:90px"
                          :disabled="true"
                        />
                      </td>
                      <td>
                        <input 
                          class="form-control input-sm"
                          type="text" 
                          :value="order.price" 
                          style="width:90px"
                          :disabled="true"
                        />
                      </td>
                      <td>
                        <span class="badge badge-danger" v-if="order.orderStatus.toLowerCase() === 'not found'">
                          {{order.orderStatus}}
                        </span>
                        <span class="badge badge-warning" v-if="order.isDuplicate === true">
                          Duplicate Product
                        </span>
                        <span class="badge badge-warning" v-if="order.orderStatus.toLowerCase() === 'not in portfolio'">
                          {{order.orderStatus}}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col">
                <div class="row justify-content-md-between align-items-md-center">
                  <div class="col-xl-3" v-if="!warningFilter && !errorFilter">Total: {{totalUploadedOrders}}</div>
                  <div class="col-xl-3" v-if="warningFilter && !errorFilter">Showing: {{totalWarningUploadedOrders}}/{{totalUploadedOrders}}</div>
                  <div class="col-xl-3" v-if="!warningFilter && errorFilter">Showing: {{totalErrorUploadedOrders}}/{{totalUploadedOrders}}</div>
                  <div class="col-xl-3" v-if="warningFilter && errorFilter">Showing: {{totalWarningUploadedOrders+totalErrorUploadedOrders}}/{{totalUploadedOrders}}</div>
                  <div class="col-xl-4">
                    <select class="form-control input-sm" v-model="perPage">
                      <option :value="20">20 records per page</option>
                      <option :value="50">50 records per page</option>
                      <option :value="100">100 records per page</option>
                    </select>
                  </div>
                  <div class="col-xl-5">
                    <div class="text-md-right float-xl-right mt-2 pagination-rounded">
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="filteredUploadedOrders.length"
                        :per-page="perPage"
                        :limit="3"
                      ></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    
  </Layout>
</template>